import assets from "assets";
import AppNav from "components/AppNav";
import AppNavModal from "components/AppNav/AppNavModal";
import BrandLogo from "components/Brandlogo";
import { SquareIconButton } from "components/Button";
import CurrencyMenu from "components/Currency";
import {
  HideExtraLarge,
  HideLarge,
  HideMedium,
  ShowLarge,
} from "components/Hide";
import Row, { RowFixed } from "components/Row";
import Search from "components/Search";
import { useEffect, useState } from "react";
import { LogOut } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { useToGetUserInfo } from "store/user/hooks";
import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;

const IconWrapper = styled.img`
  padding: 0px 18px;
`;

function Header() {
  const [hideNav, setHideNav] = useState<boolean>(true);
  const [showYootsHeader, setShowYootsHeader] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { wallet } = useToGetUserInfo();
  const onLinkClicked = () => {
    if (!wallet) {
      window.localStorage.setItem("hideNav", "true");
    }
    setHideNav(true);
  };
  const logOut = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    if (pathname.startsWith("/")) {
      setShowYootsHeader(true);
    } else {
      setShowYootsHeader(false);
    }
  }, [pathname, showYootsHeader]);

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <BrandLogo hideNavOnLoc={() => setHideNav(true)} />
        {showYootsHeader ? null : (
          <>
            <HideLarge>
              <AppNav />
            </HideLarge>
            <NavModalforMobile>
              <AppNavModal isOpen={!hideNav} onClose={onLinkClicked} />
            </NavModalforMobile>
          </>
        )}
      </LogoWrapper>
      <Row style={{ gap: ".5rem", width: "fit-content" }}>
        <CurrencyMenu />
        {/* <div className="_logoutBtn">
          <LogOut style={{ cursor: "pointer" }} onClick={logOut} />
          <span className="_tooltiptext">Logout</span>
        </div> */}
      </Row>
    </HeaderWrapper>
  );
}
const HeaderWrapper = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 62px;
  position: relative;
  ._logoutBtn {
    position: relative;
    z-index: 10;
    ._tooltiptext {
      visibility: hidden;
      width: fit-content;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 0.25rem 0.5rem;
      font-size: 0.8rem;
      background: ${({ theme }) => theme.disabled};
      /* Position the tooltip */
      position: absolute;
      top: 35px;
      left: -10px;
    }
    &:hover {
      transform: scale(1.05);
    }
    &:hover ._tooltiptext {
      visibility: visible;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0px 16px;
    height: 64px;
  `};
`;

const NavModalforMobile = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToLarge`
     display:block;
  `};
`;
export default Header;
