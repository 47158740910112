import { SmallOptionButton } from "components/Button";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { TabularNumsText } from "components/shared";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";

import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import Row from "components/Row";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import {
  ChainType,
  ChartDayData,
  GenericChartSeries,
  TimeWindow,
  TradeActionWindow,
} from "types";
import { unixToDate } from "utils/date";
import { formatAmount } from "utils/numbers";
import { getScrollToElementPath } from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";
import { getChainTypeLogo } from "utils/logo";
import { GenericImageWrapper } from "components/Logo";
import { useSolanaSalesData } from "data/yoots/y00tsSales";
const twitterShareScrollPath = getScrollToElementPath(null, null);
const snapshotDownloadIconId = "snapshot-sales-graph-download-icon";
const snapshotBrandLogoId = "snapshot-sales-graph-brand-logo";

const ButtonList = ({
  timeWindow,
  setTimeWindow,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
        ].map((option, idx) => {
          return (
            <SmallOptionButton
              key={idx}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

export type graphProps = {
  chartData: any[] | undefined;
  refGraph: React.RefObject<HTMLDivElement> | any;
};

function SalesGraph({ chartData, refGraph }: graphProps) {
  const theme = useTheme();
  const isBarGraph = false;
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.threeMonth);
  const [totalSales, setTotalSales] = useState(0);

  const [chainType, setChainType] = useState(ChainType.polygon);
  const [tradeActionWindow, setTradeActionWindow] = useState(
    TradeActionWindow.volume
  );
  const { isMobile } = useMatchBreakpoints();
  const solanaData = useSolanaSalesData();

  let solanaGraphData = solanaData?.data?.salesSummaryRecords && solanaData?.data?.salesSummaryRecords.map((item: any) => {
    return {
      date: new Date(item?.date)?.getTime() / 1000,
      qty: item?.totalTransactions
    }
  })

  const downloadScreenshot = useTakeScreenshot({
    ref: refGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });;

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  const FilterDate = (data: ChartDayData[], timeWindow: TimeWindow) => {
    let filteredData: ChartDayData[] | any = [];
    switch (timeWindow) {
      case TimeWindow.threeMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 7776000
        );
        break;
      case TimeWindow.sixMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 15552000
        );
        break;
      case TimeWindow.oneYear:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 31104000
        );
        break;
      default:
        filteredData = data;
        break;
    }
    let totalSales = filteredData.reduce(
      (a: any, b: any) => a + Number(b.qty),
      0
    );
    setTotalSales(totalSales);
    return filteredData;
  };
  const formattedChartData = useMemo(() => {
    if (chartData && chainType === "Polygon") {
      return FilterDate(chartData, timeWindow).map((day: any) => {
        return {
          time: unixToDate(day.date),
          qty: day.qty - 0,
        };
      });
    }  else if (solanaGraphData && chainType === ChainType.sol) {
      return FilterDate(solanaGraphData, timeWindow).map((day: any) => {
        return {
          time: unixToDate(day.date),
          qty: day.qty - 0,
        };
      });
    }
    else {
      return [];
    }
  }, [chartData, timeWindow, chainType]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "qty", name: "qty", color: "#0057FF" },
  ];

  return (
    <>
      <GraphTableTopHeader
        title={"Sales"}
        tooltip={`Number of times a NFT was sold from this collection`}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `} />
      <HorizontalDivider />
      <ChartWrapper id="collection-sales">
        <LineAreaChart
          data={formattedChartData}
          // Change this according to dataset
          series={formattedChartSeries}
          setValue={setVolumeHover}
          setLabel={setRightLabel}
          value={volumeHover}
          label={rightLabel}
          activeWindow={timeWindow}
          topLeft={
            <AutoColumn gap="4px">
              <Row gap="8px">
                <TEXT.LargeHeader>
                  <TabularNumsText>
                    {formatAmount(totalSales, 2)}
                  </TabularNumsText>
                </TEXT.LargeHeader>
              </Row>

              <TEXT.SubBody fontSize="16px">
                <TabularNumsText></TabularNumsText>
              </TEXT.SubBody>
              <TEXT.SmallHeader fontSize="12px" height="14px">
                {rightLabel ? (
                  <TabularNumsText>{rightLabel} (UTC)</TabularNumsText>
                ) : null}
              </TEXT.SmallHeader>
            </AutoColumn>
          }
        />
      </ChartWrapper>
    </>
  );
}

export default SalesGraph;