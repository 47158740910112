import axios from "axios";
import Row, { AutoRow, ResponsiveRow, ResponsiveRowWrap } from "components/Row";
import React, { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OverviewProfileCard } from "components/DataCard";
import { PageWrapper } from "pages/styled";
import styled, { useTheme } from "styled-components";
import Column, { AutoColumn } from "components/Column";
import Spacer from "components/Spacer";
import { useProtocolChartData } from "store/protocol/hooks";
import { TEXT } from "theme/texts";
import QuestionHelper from "components/QuestionHelper";
import { ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { utils, ethers } from "ethers";
import { useCurrencyUnit } from "store/user/hooks";
import { formatAmount } from "utils/numbers";
import Tabview from "components/Tab";
import assets from "assets";
import { HorizontalDivider } from "components/Divider/inde";
import OverviewSection from "./overview";
import TradingDataSection from "./tradingData";
import OwnersSection from "./owners";
import ActivitySection from "./activity";
import InsightsSection from "./insights";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import LeaderboardSection from "./Leaderboard";

const TABS = {
  OVERVIEW: "overview",
  TRADING_DATA: "trading_data",
  OWNERS: "owners",
  ACTIVITY: "activity",
  INSIGHTS: "insights",
  LEADERBOARD: "leaderboard",
};
const TabviewConfig: { label: string; value: string }[] = [
  {
    label: "Overview",
    value: TABS.OVERVIEW,
  },
  {
    label: "Trading Data",
    value: TABS.TRADING_DATA,
  },
  {
    label: "Owners",
    value: TABS.OWNERS,
  },
  {
    label: "Activity",
    value: TABS.ACTIVITY,
  },
  {
    label: "Leaderboard",
    value: TABS.LEADERBOARD,
  }
  // {
  //   label: "Insights",
  //   value: TABS.INSIGHTS,
  // },
];

const YootsDashboard = () => {
  const theme = useTheme();
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const refGraph = createRef();
  const { currency } = useCurrencyUnit();
  const [selectedTab, setSelectedTab] = useState(TABS.OVERVIEW);
  const profileData = {
    image: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeig3ubxvctwfiklv657uqyrjt5crcjpxi7lv4ib224niprl6belkxa.ipfs.nftstorage.link//",
    name: "rekt dogs",
    contractAddress: "0xace8187b113a38f83bd9c896c6878b175c234dcc",
    erc_type: "ERC721",
    marketplace: {
      opensea: "https://opensea.io/collection/rektdogs",
      rarible: "https://rarible.com/collection/polygon/0xace8187b113a38f83bd9c896c6878b175c234dcc/items",
      magiceden: "https://magiceden.io/collections/polygon/rektdogs",
    },
    twitter: "https://www.twitter.com/rektdogs",
    discord: "https://discord.gg/rektdogs",
    website: "http://www.rektdogs.com/",
  };
  function handleTabChange(value: string): void {
    setSelectedTab(value);
  }

  return (
    <Wrapper>
      <HorizontalDivider />
      <ProfileViewWrapper>
        <TEXT.LargeHeader
          fontSize={"clamp(1.5rem,4vw,3rem)"}
          style={{ padding: "1rem 0" }}
        >
          Welcome Back Rekt Dogs Team !
        </TEXT.LargeHeader>
        <Column style={{ gap: "1rem" }}>
          <ProfileHeader profileData={profileData} />
          <Tabview
            data={TabviewConfig}
            value={selectedTab}
            onClick={handleTabChange}
          />
        </Column>
      </ProfileViewWrapper>
      <HorizontalDivider />
      <BodyWrapper>
        {(() => {
          switch (selectedTab) {
            case TABS.OVERVIEW:
              return <OverviewSection />;
            case TABS.TRADING_DATA:
              return <TradingDataSection />;
            case TABS.OWNERS:
              return <OwnersSection />;
            case TABS.ACTIVITY:
              return <ActivitySection />;
            // case TABS.INSIGHTS:
            //   return <InsightsSection />;
            case TABS.LEADERBOARD:
              return <LeaderboardSection />;
            default:
              return <></>;
          }
        })()}
      </BodyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const ProfileViewWrapper = styled(PageWrapper)`
  padding-bottom: 0px;
  ._graph {
    border: 1px solid ${(props) => props.theme.disabled};
  }
  ._profileNameLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  ._volAndFloorGraphsCtr,
  ._salesAndOwnersGraphsCtr {
    overflow: hidden;
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      flex-direction:column;
    `}
  }
`;

const BodyWrapper = styled(PageWrapper)`
  width: 100%;
  padding-top: 0;
`;
export default YootsDashboard;
