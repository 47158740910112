import { AutoRow, ResponsiveRow } from "components/Row";
import { PageWrapper } from "pages/styled";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { GraphResponsiveStyledCard } from "../component";
import ActivityMapGraph from "./VolumeGraph";
import PolygonBridgingGraph from "./FloorAndAveragePrices";
import SalesGraph from "./SalesGraph";
import ListingAndSalesTables from "./ListingsAndSales";
import TopOwnersTable from "./TopOwnersTable";
import WashTradingTable from "./WashTradingTable";
import TopOwners from "pages/Profile/components/TopOwners";
import { useProtocolChartData } from "store/protocol/hooks";
import { useCurrencyUnit } from "store/user/hooks";
import axios from "axios";
import { useZettaTradingGraph } from "data/yoots/zetta/tradingGraph";

export default function TradingDataSection() {
  const refSales: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refListing: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refVolume: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refFloorPrice: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refTopOwners: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refWashTrading: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);

    const profileID = "0xace8187b113a38f83bd9c896c6878b175c234dcc";
    const [chartData] = useProtocolChartData();
    const [profileData, setProfileData] = useState<any>(null);
    const [owners, setOwners] = useState<any>(null);
    const [sales, setSales] = useState<any>(null);
    const [listing, setListing] = useState<any>(false);
    const [salesVolume, setSalesVolume] = useState<any>(null);
    const [floorPriceData, setFloorPriceData] = useState<any>(null);
    const [ownersCountGraph, setOwnersCountGraph] = useState<any>(null);
    const [tokenSupplyGraph, setTokenSupplyGraph] = useState<any>(null); 
  

  const [topSales, setTopSales] = useState(null);

  const [mutualCollections, setMutualCollections] = useState<any>(null);
  const [financialProfileData, setFinancialProfileData] = useState<any>(null);

  const { currency } = useCurrencyUnit();
  const polygonTradingData = useZettaTradingGraph();
  let VolumeData = polygonTradingData?.records && polygonTradingData?.records?.map((item: any) => {
    return {
      date: new Date(item?.data_creation_date)?.getTime() / 1000,
      volumeUSD: item?.volume_usd,
      volume_in_eth: item?.volume_eth,
      volume_in_matic: item?.volume_matic,
    }
  })
  // reverse the date order
  // VolumeData = VolumeData?.reverse();
  // // VolumeData = VolumeData?.slice(0,10);
 
  
 // if the volume data is not available for 3 months, then add previous dates with 0 volume 
  if ( VolumeData!?.length < 90) {
    let lastDate = VolumeData![VolumeData?.length! - 1]?.date;
    for (let i = 0; i < 90 - VolumeData!?.length!; i++) {
      lastDate = lastDate - 86400;
      VolumeData!.push({
        date: lastDate,
        volumeUSD: 0,
        volume_in_eth: 0,
        volume_in_matic: 0,
      });
    }
  }
  VolumeData = VolumeData?.reverse();

  
  
  
  let FloorData = polygonTradingData?.records && polygonTradingData?.records?.map((item: any) => {
    return {
      date: new Date(item?.data_creation_date)?.getTime() / 1000,
      min_in_usd: item?.floor_price_usd,
      min_in_eth: item?.floor_price_eth,
      min_in_matic: item?.floor_price_matic,
      avg_in_usd: item?.avg_price_usd,
      avg_in_eth: item?.avg_price_eth,
      avg_in_matic: item?.avg_price_matic,
      max_in_usd: item?.floor_price_usd,
      max_in_eth: item?.floor_price_eth,
      max_in_matic: item?.floor_price_matic,
    }
  })
  // if ( VolumeData!?.length < 90) {
  //   let lastDate = VolumeData![VolumeData?.length! - 1]?.date;
  //   for (let i = 0; i < 90 - VolumeData!?.length!; i++) {
  //     lastDate = lastDate - 86400;
  //     VolumeData!.push({
  //       date: lastDate,
  //       volumeUSD: 0,
  //       volume_in_eth: 0,
  //       volume_in_matic: 0,
  //     });
  //   }
  // }
  if ( FloorData!?.length < 90) {
    let lastDate = FloorData![FloorData?.length! - 1]?.date;
    for (let i = 0; i < 90 - FloorData!?.length!; i++) {
      lastDate = lastDate - 86400;
      FloorData!.push({
        date: lastDate,
        min_in_usd: 0,
        min_in_eth: 0,
        min_in_matic: 0,
        avg_in_usd: 0,
        avg_in_eth: 0,
        avg_in_matic: 0,
        max_in_usd: 0,
        max_in_eth: 0,
        max_in_matic: 0,
      });
    }
  }
  // reverse the date order
  FloorData = FloorData?.reverse();

  let SalesData = polygonTradingData?.records && polygonTradingData?.records?.map((item: any) => {
    return {
      date: new Date(item?.data_creation_date)?.getTime() / 1000,
      qty: item?.sales_count,
    }
  })
  // reverse the date order
  if ( SalesData!?.length < 90) {
    let lastDate = SalesData![SalesData?.length! - 1]?.date;
    for (let i = 0; i < 90 - SalesData!?.length!; i++) {
      lastDate = lastDate - 86400;
      SalesData!.push({
        date: lastDate,
        qty: 0,
      });
    }
  }

  SalesData = SalesData?.reverse();

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const fetchProfileData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/collectionDetails/${profileID}`
      );
      setProfileData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const FetchSales = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/collection/sales/${profileID}`
      );
      setTopSales(
        res?.data?.data?.TopSales?.length > 0
          ? res?.data?.data?.TopSales[0]
          : null
      );
      setSales(res?.data?.data?.TopSales);
      setListing(res?.data?.data?.TopListing);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSalesVolume = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profileData/sales/${profileID}?duration=2`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item: any) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          qty: item?.quantity,
          volume_in_matic: item?.volume,
          volume_in_eth: item?.volume_in_eth,
          volumeUSD: item?.volume_in_usd,
        };
      });
      console.log(data);
      setSalesVolume(data);
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchFloorPriceData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profileData/tokenPrice/${profileID}?duration=2`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item: any) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          min: Number(item?.min),
          max: Number(item?.max),
          avg: Number(item?.avg),
          min_in_eth: item?.min_in_eth,
          max_in_eth: item?.max_in_eth,
          avg_in_eth: item?.avg_in_eth,
          min_in_usd: item?.min_in_usd,
          max_in_usd: item?.max_in_usd,
          avg_in_usd: item?.avg_in_usd,
        };
      });
      setFloorPriceData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchOwnersCountGraph = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profileData/owners/${profileID}`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item: any) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          count: item?.count,
        };
      });
      setOwnersCountGraph(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTokenSupplyGraph = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/profileData/supply/${profileID}`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item: any) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          minted: item?.minted,
          burned: item?.burned,
          totalMinted: item?.totalMinted,
          totalBurned: item?.totalBurned,
        };
      });
      setTokenSupplyGraph(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const FetchVolume = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/collection/volume/${profileID}`
  //     );
  //     console.log(res?.data?.data);
  //     setVolume(res?.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const [itemIdx, setItemIdx] = useState(1);

  useEffect(() => {
    fetchProfileData();
    FetchSales();
    fetchSalesVolume();
    async function fetchData1() {
      await delay(1000);
      fetchFloorPriceData();
      await delay(1000);
      fetchOwnersCountGraph();
      await delay(1000);
      fetchTokenSupplyGraph();
    }
    fetchData1();
  }, [profileID]);
  return (
    <Wrapper>
      <ResponsiveRow width={"100%"} gap="26px">
        <GraphResponsiveStyledCard
          ref={refVolume}>
          <ActivityMapGraph
            refGraph={refVolume}
            chartData={salesVolume}
          />
        </GraphResponsiveStyledCard>
        <GraphResponsiveStyledCard
          ref={refFloorPrice}
        >
          <PolygonBridgingGraph
            refGraph={refFloorPrice}
            chartData={floorPriceData}
          />
        </GraphResponsiveStyledCard>
      </ResponsiveRow>
      <GraphResponsiveStyledCard
        ref={refSales}
      >
        <SalesGraph refGraph={refSales} chartData={salesVolume} />
      </GraphResponsiveStyledCard>
      <ResponsiveRow width={"100%"} gap="26px">
        <GraphResponsiveStyledCard
          ref={refListing}>
         {(
                <ListingAndSalesTables
                  salesData={sales?.length > 0 ? sales : []}
                  ListingData={listing.length > 0 ? listing : []}
                />
            )}
        </GraphResponsiveStyledCard>
        {/* <GraphResponsiveStyledCard
          ref={refGraph}
        >
          <TopOwners data={undefined} />
        </GraphResponsiveStyledCard> */}
      </ResponsiveRow>
      {/* <GraphResponsiveStyledCard>
        <WashTradingTable />
      </GraphResponsiveStyledCard> */}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
