import { OverviewProfileCard } from "components/DataCard";
import Row, { ResponsiveRowWrap, ResponsiveRowWrap2 } from "components/Row";
import React from "react";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import styled from "styled-components";
import { ListButtonWrapper } from "pages/YootsDashboard/styled";
import { ChainType } from "types";
import { SmallOptionButton } from "components/Button";
import { GenericImageWrapper } from "components/Logo";
import { getChainTypeLogo } from "utils/logo";
import { TEXT } from "theme/texts";
import { useSolanaStats } from "data/yoots/y00tsStats";
import { useZettaCollectionStats } from "data/yoots/zetta/collectionStats";
import { useCurrencyUnit } from "store/user/hooks";
import { useSolanaListingAndSales } from "data/yoots/y00tsSalesAndListing";
import { useZettaBridging } from "data/yoots/zetta/BridgingCount";
import { usePolygonListing } from "data/yoots/PolygonListing";
import { useRektDogsOverview } from "data/yoots/rektdogsOverview";
import axios from "axios";
function OverviewDataCards() {
    const { isMobile, isTablet } = useMatchBreakpoints();
    const [chainType, setChainType] = React.useState<ChainType>(ChainType.polygon);
    const solanaData = useSolanaStats()

    const [owners, setOwners] = React.useState(false);
    const profileID = '0xace8187b113a38f83bd9c896c6878b175c234dcc';
    const fetchOwnersCountGraph = async () => {
        try {
          const fetchData = await axios.get(
            `${process.env.REACT_APP_API_URL}/profileData/owners/${profileID}?duration=3`,
            {
              timeout: 10000,
            }
          );
          setOwners(fetchData?.data?.data[fetchData?.data?.data?.length - 1]?.count);
        }
        catch (error) {
          console.log(error);
        }
      };
    React.useEffect(() => {
        fetchOwnersCountGraph();
    }, []);
          

    // let totalBridged = bridged?.records && bridged?.records?.reduce((a:any, b:any) => a + b?.num_transactions, 0);
    // console.log("totalBridged", totalBridged);
    const { currency } = useCurrencyUnit();
    const PolygonData = useZettaCollectionStats();
    const LisitngData = useSolanaListingAndSales();
    const Listings = usePolygonListing();
    const dataList: any = [
      {
          title: "Floor Price",
          count: 0,
          priceCount: Listings?.data && Listings?.data?.floor_matic ? currency?.symbol === "ETH" ? Listings?.data?.floor_eth : currency?.symbol === "USD" ? Listings?.data?.floor_usd : Listings?.data?.floor_matic : 0,
          priceChansge: 0.000,
          tooltip: `Minimum ${chainType === "Polygon" ? currency?.name?.toLocaleUpperCase() : "Solana"} needed to buy a NFT from the collection`,
          solCount: solanaData?.data?.nftSummary && solanaData?.data?.nftSummary?.floorPrice,
      },
      {
          title: "Average price (24h)",
          count: 0,
          priceCount: PolygonData?.records && PolygonData?.records[0]?.avg_price_eth ? currency?.symbol === "ETH" ? PolygonData?.records[0]?.avg_price_eth : currency?.symbol === "USD" ? PolygonData?.records[0]?.avg_price_usd : PolygonData?.records[0]?.avg_price_matic : 0,
          priceChange: 0,
          tooltip: `Average amount of ${chainType === "Polygon" ? currency?.name?.toLocaleUpperCase() : "Solana"} needed to buy a NFT from this collection`,
          solCount: solanaData?.data?.nftSummary && solanaData?.data?.nftSummary?.avgPriceSol,
          solPriceChange: 0,
      },
      {
          title: "Number of Listed NFTs",
          count: (Listings?.data && Listings?.data?.totalListedCount) || 0,
          priceChange: 0,
          tooltip: `Number of NFTs from this collection listed for sale`,
          solCount: LisitngData?.data?.listing && LisitngData?.data?.listing?.length,
          solPriceChange: 0,
      },
      {
          title: "No. of Unique Owners",
          count: owners || 0,
          priceChange: 0,
          tooltip: `Number of unique wallets that hold atleast 1 NFT from this collection`,
          solCount: undefined,
          solPriceChange: 0,
      },
      {
          title: "Volume (24h)",
          count: 0,
          priceCount: PolygonData?.records && PolygonData?.records[0]?.volume_eth ? currency?.symbol === "ETH" ? PolygonData?.records[0]?.volume_eth : currency?.symbol === "USD" ? PolygonData?.records[0]?.volume_usd : PolygonData?.records[0]?.volume_matic : 0,
          priceChange: 0,
          tooltip: `Total ${chainType === "Polygon" ? currency?.name?.toLocaleUpperCase() : "Solana"} transacted via buying and selling of the NFTs in this collection`,
          solCount: solanaData?.data?.nftSummary && solanaData?.data?.nftSummary?.volume,
          solPriceChange: 0,
      }, {
          title: "Market Cap",
          count: 0,
          priceCount: PolygonData?.records && PolygonData?.records[0]?.market_cap_eth ? currency?.symbol === "ETH" ? PolygonData?.records[0]?.market_cap_eth : currency?.symbol === "USD" ? PolygonData?.records[0]?.market_cap_usd : PolygonData?.records[0]?.market_cap_matic : 0,
          priceChange: 0,
          tooltip: `Total worth of this collection in ${chainType === "Polygon" ? currency?.name?.toLocaleUpperCase() : "Solana"}`,
          solCount: solanaData?.data?.data && solanaData?.data?.data?.trendingVolume_all,
          solPriceChange: 0,
      },
      {
          title: "Total Supply",
          count: PolygonData?.records && PolygonData?.records[0]?.circulating_supply,
          tooltip: `Number of NFTs present in this collection`,
          solCount: solanaData?.data?.nftSummary && solanaData?.data?.nftSummary?.supply,
  
      },
  ]
    return (
      <div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <ListButtonWrapper withoutDivider={true}>
            {[ChainType.polygon, ChainType.sol].map((option, index) => {
              return (
                <SmallOptionButton
                  key={index}
                  active={chainType === option}
                  onClick={() => setChainType(option)}
                >
                  <Row gap="4px">
                    <GenericImageWrapper
                      src={getChainTypeLogo(option)}
                      size={isMobile ? "16px" : "12px"}
                    />
                    {!isMobile && (
                      <TEXT.SmallHeader fontWeight={600} fontSize={12}>
                        {option}
                      </TEXT.SmallHeader>
                    )}
                  </Row>
                </SmallOptionButton>
              );
            })}
          </ListButtonWrapper>
        </div> */}
        <ResponsiveRowWrap2>
          {dataList.map((value: any) => {
            if (value)
              return (
                <OverviewProfileCard
                  title={
                    chainType === "Polygon"
                      ? value.title
                      : value.solTitle
                      ? value.solTitle
                      : value.title
                  }
                  tooltip={value.tooltip}
                  count={chainType === "Polygon" ? value.count : value.solCount}
                  percentCount={value.percentCount}
                  priceCount={
                    chainType === "Polygon"
                      ? value.priceCount
                      : value.solPriceCount
                  }
                  priceChange={
                    chainType === "Polygon"
                      ? value.priceChange
                      : value.solPriceChange
                  }
                  text={value.text}
                  {...{
                    minHeight: isMobile ? "86px!important" : "150px!important",
                    padding: isMobile ? "1rem" : "2rem",
                  }}
                />
              );
            else return null;
          })}
        </ResponsiveRowWrap2>
      </div>
    );
}

export default OverviewDataCards;

const CustomResponsiveRowWrap = styled(ResponsiveRowWrap)`
  ${({ theme }) => theme.mediaWidth.upToLarge`
min-width: 100px;
grid-template-columns: 48% 48%!important;
`}
  ${({ theme }) => theme.mediaWidth.upToMedium`
min-width: 100px;
grid-template-columns: 48% 48%!important;
`}
`;
