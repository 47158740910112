import assets from "assets";
import axios from "axios";
import { PrimaryButton } from "components/Button";
import Column from "components/Column";
import { HideSmall } from "components/Hide";
import { StyledInput } from "components/Input/TextField";
import { CircledImageCtr } from "components/Logo";
import Row from "components/Row";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Twitter } from "react-feather";
import { toast } from "react-hot-toast";
import styled, { useTheme } from "styled-components";
import { TEXT } from "theme/texts";
import { useModal } from "connectkit";
import { useToGetUserInfo } from "store/user/hooks";

const SignupBanner = ({
  setPopup,
  showPopup,
}: {
  setPopup: () => React.Dispatch<React.SetStateAction<boolean>> | any;
  showPopup: boolean;
}) => {
  const { setOpen } = useModal();
  const { wallet } = useToGetUserInfo();
  let appException = localStorage.getItem("app") === "true" ? true : false;

  console.log(showPopup);
  const [user, setUser] = useState<boolean>(false);
  const [userRank, setUserRank] = useState<any>(null);

  const formRef = useRef<HTMLFormElement>(null);
  const postData = async (wallet: any) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/${wallet}`);
       if(res?.data?.isConnected === true){
        setUser(true)
        getRank(wallet)
       }
     
    } catch (error) {
      console.log(error);
     
    }
  };

  const getRank = async (Wallet: any) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL1}/aggregations/tradeLeaderboard?sortBy=volume_usd_bought&wallet=${Wallet}&overall=true`);
        setUserRank(res?.data?.rank)
    } catch (error) {
      console.log(error);
     
    }
  };

  useEffect(() => {
    if (wallet) {
        postData(wallet);
    }
    }, [wallet]);
 
  return (
    <SignupBannerWrapper>
         {!user ? 
         <>
      <Row gap="1rem">
      
        <Column style={{ gap: ".5rem" }}>
          <TEXT.LargeHeader
            fontSize={"clamp(1.25rem, 3vw, 2rem)"}
            className="_gradientText"
          >
            Claim your spot on the Trader Leaderboard
          </TEXT.LargeHeader>
        </Column>
      </Row>
      <Column
        style={{
          gap: "1rem",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        {/* <form
          style={{ width: "100%", maxWidth: "400px" }}
          ref={formRef}
          onSubmit={onEmailSubmit}
        >
          <Row gap=".5rem">
            <StyledInput
              type="email"
              placeholder="Add email address here"
              style={{
                background: `${useTheme().bgFooter}`,
                borderRadius: ".5rem",
                padding: ".5rem 1rem",
                border: `1px solid ${useTheme().disabledVar3}`,
                fontSize: "clamp(.7rem,2vw,1rem)",
              }}
              required
              onChange={(e) => setEmail(e.target.value)}
            /> */}
        <PrimaryButton
          onClick={() => {
         
            if (wallet) {
              window.location.href = process.env.REACT_APP_API_URL + `/twitter/login?wallet=${wallet}&redirect_uri=${window.location.href}?location=leaderboard`;
            } else {
              setOpen(true);
            }
          }}
          style={{
            background:
              "linear-gradient(63.38deg, #8E7DD2 5.47%, #2C3284 77.8%, #181B4B 124.16%) !important;",
            width: "250px",
            borderRadius: ".5rem",
            padding: ".5rem",
          }}
        >
          <TEXT.SmallHeader fontSize={"clamp(.7rem,2vw,1rem)"}>
          {!wallet ? "Claim Now" : "Connect your twitter"}
          </TEXT.SmallHeader>
        </PrimaryButton>
        {/* </Row>
        </form> */}
        <HideSmall>
          {/* <Row gap=".5rem" width={"fit-content"}>
            <TEXT.Body>Drop a Follow on</TEXT.Body>
            <a
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                gap: ".25rem",
              }}
              href="https://twitter.com/LayerEhq"
              target="_blank"
            >
              <Twitter />
              <TEXT.SmallHeader>LayerEhq</TEXT.SmallHeader>
            </a>
          </Row> */}
        </HideSmall>
      </Column>
      </>
        :  
        <>
        <Row gap="1rem">
        
          <Column style={{ gap: ".5rem" }}>
            <TEXT.LargeHeader
              fontSize={"clamp(1.25rem, 3vw, 2rem)"}
              className="_gradientText"
            >
                {!userRank ? 
                    "Oops! You are not on the Leaderboard"
                : 
                `You are on Top Trader Leaderboard at Rank #${userRank}` }
            </TEXT.LargeHeader>
            <TEXT.Body>
            Rank is based on the total volume spent.
            </TEXT.Body>
          </Column>
        </Row>
        <Column
          style={{
            gap: "1rem",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          {/* <form
            style={{ width: "100%", maxWidth: "400px" }}
            ref={formRef}
            onSubmit={onEmailSubmit}
          >
            <Row gap=".5rem">
              <StyledInput
                type="email"
                placeholder="Add email address here"
                style={{
                  background: `${useTheme().bgFooter}`,
                  borderRadius: ".5rem",
                  padding: ".5rem 1rem",
                  border: `1px solid ${useTheme().disabledVar3}`,
                  fontSize: "clamp(.7rem,2vw,1rem)",
                }}
                required
                onChange={(e) => setEmail(e.target.value)}
              /> */}
          {/* <PrimaryButton
            onClick={() => {
           
              if (wallet) {
                window.location.href = process.env.REACT_APP_API_URL + `/twitter/login?wallet=${wallet}&redirect_uri=${window.location.href}?location=leaderboard`;
              } else {
                setOpen(true);
              }
            }}
            style={{
              background:
                "linear-gradient(63.38deg, #8E7DD2 5.47%, #2C3284 77.8%, #181B4B 124.16%) !important;",
              width: "250px",
              borderRadius: ".5rem",
              padding: ".5rem",
            }}
          >
            <TEXT.SmallHeader fontSize={"clamp(.7rem,2vw,1rem)"}>
            {!wallet ? "Claim your spot" : "Connect your twitter"}
            </TEXT.SmallHeader>
          </PrimaryButton> */}
          {/* </Row>
          </form> */}
          <HideSmall>
            <Row gap=".5rem" width={"fit-content"}>
                {userRank ? 
                   <>
                   <TEXT.Body>
                    Shill your rank on Twitter
                   </TEXT.Body>
                   <a
                     style={{
                       display: "flex",
                       alignItems: "center",
                       justifyContent: "center",
                       color: "#fff",
                       gap: ".25rem",
                     }}
                     href={`https://twitter.com/intent/tweet?text=Hot diggity dog!🤠%0A%0AI'm the %23${userRank} trader for @RektDogs😎I'm puttin in all my bags, where are y'all?%0A%0Acheck out your RektDogs Trader Rank on rektdogs.surfaceboard.xyz by @LayerEhq`}
                     target="_blank"
                   >
                     <Twitter />
                     <TEXT.SmallHeader>Tweet</TEXT.SmallHeader>
                   </a> 
                   </> :
                <>
              <TEXT.Body>Drop a Follow on</TEXT.Body>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  gap: ".25rem",
                }}
                href="https://twitter.com/LayerEhq"
                target="_blank"
              >
                <Twitter />
                <TEXT.SmallHeader>LayerEhq</TEXT.SmallHeader>
              </a> 
              </>
               }
            </Row>
          </HideSmall>
        </Column>
        </> }
        
    </SignupBannerWrapper>
  );
};

const SignupBannerWrapper = styled(Row)`
  height: 140px;
  padding: 1rem;
  gap: 1rem;
  overflow: hidden;
  justify-content: space-between;
  background: url(${assets.images.bgSignupBanner}) no-repeat,
    linear-gradient(180deg, #020202 0%, #1c1d2b 100%);
  box-shadow: 0px 3.5px 8.75px rgba(0, 0, 0, 0.3);
  background-size: cover;
  ._gradientText {
    background: linear-gradient(
      62.79deg,
      #a726c1 5.57%,
      #803bdf 80.18%,
      #7b3fe4 90.36%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction:column;
    height:initial;
  `}
`;
export default SignupBanner;