import axios from "axios";
import { useMemo, useState } from "react";



interface api {
    loading: boolean;
    error: boolean;
    data: any;
}

const getData = async () => {
    try {
        const { data } = await axios.get(   
            process.env.REACT_APP_API_URL1 + "/aggregations/listedNFTPolygon?collection=rektdogs"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function usePolygonListing() {
    const [api, setAPIData] = useState<api>({
        loading: true,
        error: false,
        data: [] || undefined
    });



    useMemo(async () => {
        const data = await getData();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
