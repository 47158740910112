import { AutoRow, ResponsiveRow } from "components/Row";
import { PageWrapper } from "pages/styled";
import React from "react";
import styled from "styled-components";
import { GraphResponsiveStyledCard } from "../component";
import  ProfitLeaderBoard from "./ProfitLeaderboard";

import { useZettaActivityCount } from "data/yoots/zetta/ActivityCount";
import { cardsData } from "./ProfitLeaderboard/data";
import CollectionCard from "./ProfitLeaderboard/collectionCard";
import SignupBanner from "./SignupBanner";
export default function ActivitySection() {
  const refGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const [showPopup, setShowPopup] = React.useState(false);
  

    
  return (
    <Wrapper>
    
      <ResponsiveRow width={"100%"} gap="10px">
        <SignupBanner showPopup={showPopup} setPopup={() => setShowPopup(!showPopup)} />

      </ResponsiveRow>
      
      {window.innerWidth > 768 && 
        <ResponsiveRow width={"100%"} gap="10px" >
            {cardsData?.map((card, idx) => (
                 <GraphResponsiveStyledCard>
              <CollectionCard card={card} key={idx} index={idx}/>
              </GraphResponsiveStyledCard>
            ))}
        </ResponsiveRow>
    }
            
        
       
       
       
     
      <ResponsiveRow width={"100%"} gap="26px">
      <GraphResponsiveStyledCard>
          <ProfitLeaderBoard  />
        </GraphResponsiveStyledCard>
        </ResponsiveRow>
    
    </Wrapper>
  );
}

const CollectionsPageCtr = styled(PageWrapper)`
  ${(props) => props?.theme?.flexColumnNoWrap}
  gap:2rem;
  width:100%;
  ._cardsCtr {
    display: grid;
    gap: 1rem;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */

    ._cardsNav {
      display: none;
    }
    ._cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      height: 100%;
      gap: 1rem;
      min-height: 300px;
     
      flex-wrap:wrap;
        align-items:center;
        justify-content:center;
        grid-auto-flow: dense;
        
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToMedium`
    ._cards {
      display:flex;
      overflow-x:auto;
    }
     ._cardsNav{
      display: flex;
      width:100%;
      align-items:center;
      gap:.5rem;
      justify-content:center;
    }
    `}
  }

  .cardContentCardgBox {
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 42px;
      line-height: 110%;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #6b7280;
    }
  }
`;


const Wrapper = styled.div`
  width: 100%;
`;
