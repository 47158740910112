import { SecondaryButton, SquareIconButton } from "components/Button";
import Column, { AutoColumn } from "components/Column";
import Row, { AutoRow, ResponsiveRowWrap } from "components/Row";
import React, { FormEvent, useRef, useState } from "react";
import styled from "styled-components";
import { ExternalLink } from "theme/components";
import { StyledInternalLink } from "theme/components";
import { TEXT } from "theme/texts";
import { GenericImageWrapper } from "components/Logo";
import useTheme from "hooks/useTheme";
import assets from "assets";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import BrandLogo from "components/Brandlogo";
import { HorizontalDivider } from "components/Divider/inde";
import TextField from "components/Input/TextField";
import { appRoutes } from "utils/path";
import { Copy } from "react-feather";
import axios from "axios";
import { toast } from "react-hot-toast";
import { copyTextToClipboard } from "utils/clipboardCopy";

const FooterWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bgFooter};
  padding: 4rem 0% 2rem 0%;
`;
const ResponsiveContainer = styled(Row)`
  width: 100%;
  align-items: start;
  padding: 0% 10% 1% 10%;
  gap: 10%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction:column;
padding: 1% 6% 10% 6%;
`}
`;

const DetailSection = styled.div`
  width: 54%;
  align-items: start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-bottom: 20px;
  width: 100%;
`}
`;

// const RightSection = styled(ResponsiveRowWrap)`
//    {
//     grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
//   }
// `;

const RightSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  max-width: 400px;
  margin-top: 1rem;
  gap: 1rem;
`;

const StyledExternalLink = styled(ExternalLink)`
  font-size: clamp(0.9rem, 2vw, 1rem);
  display: flex;
  align-items: center;
  gap: 0.35rem;
`;

function Footer() {
  const theme = useTheme();
  const { isMobile } = useMatchBreakpoints();
  const [email, setEmail] = useState<string>("");
  const formRef = useRef<HTMLFormElement>(null);

  const subscribeToNewsletter = async (e: FormEvent) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("Email", email?.toLowerCase());
    try {
      const res = await axios({
        method: "post",
        url: "https://script.google.com/macros/s/AKfycbwbCyzUjN3gByofPEhESgtcJSmNlP2nU_VUFjGYBa3kDJaGeRjMziRAm7yzac2ljLgwfQ/exec",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.status === 200) {
        toast.success("Subscribed Successfully!");
      } else {
        toast.error("Error subscribing to newsletter!");
      }
    } catch {
      toast.error("Error subscribing to newsletter!");
    } finally {
      formRef?.current?.reset();
    }
  };
  const openLink = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <FooterWrapper>
      <ResponsiveContainer gap={isMobile ? "68px" : "22px"}>
        <DetailSection>
          <AutoColumn gap={"1.5rem"}>
            <BrandLogo hideNavOnLoc={undefined} />
            <TEXT.Body>
              Surfaceboard is your one-stop analytical tool for all things
              Polygon NFTs.
            </TEXT.Body>
            <Row gap="16px" marginBottom={isMobile ? "18px" : "10px"}>
              <SquareIconButton
                backgroundColor={theme.bgBase}
                onClick={() => openLink("https://twitter.com/LayerEhq")}
              >
                <GenericImageWrapper src={assets.logos.twitterLogo} />
              </SquareIconButton>
              <SquareIconButton
                backgroundColor={theme.bgBase}
                onClick={() => openLink("https://discord.gg/ZCngbtb8ry")}
              >
                <GenericImageWrapper src={assets.logos.discordLogo} />
              </SquareIconButton>
              <SquareIconButton
                backgroundColor={theme.bgBase}
                onClick={() => openLink("https://instagram.com/LayerEhq")}
              >
                <GenericImageWrapper src={assets.logos.instagramLogo} />
              </SquareIconButton>
              <SquareIconButton
                backgroundColor={theme.bgBase}
                onClick={() =>
                  openLink("https://www.linkedin.com/company/layerehq/")
                }
              >
                <GenericImageWrapper src={assets.logos.linkedinLogo} />
              </SquareIconButton>
            </Row>
            <AutoColumn gap="1rem">
              <TEXT.Body>Subscribe to Newsletter for NFTsonPolygon.</TEXT.Body>
              <form onSubmit={subscribeToNewsletter} ref={formRef}>
                <Row gap="8px">
                  <TextField
                    type="email"
                    style={{
                      fontSize: "1rem",
                      border: `.85px solid ${theme.body}`,
                      borderRadius: ".35rem",
                    }}
                    onChange={(e: any) => setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                    required
                  />
                  <SecondaryButton
                    width="fit-content"
                    style={{
                      padding: ".5rem 1.15rem",
                      height: "100%",
                      minWidth: "80px",
                      border: `.85px solid ${theme.body}`,
                    }}
                    borderRadius=".25rem !important"
                  >
                    Subscribe
                  </SecondaryButton>
                </Row>
              </form>
            </AutoColumn>
          </AutoColumn>
        </DetailSection>
        <RightSection>
          {linkList.map((data) => {
            return (
              <Column
                style={{
                  gap: ".75rem",
                  width: "fit-content",
                }}
              >
                <TEXT.SmallHeader fontSize={"clamp(0.9rem, 2vw, 1rem)"}>
                  {data.title}
                </TEXT.SmallHeader>

                {data.elements.map((element) => (
                  // <a target={'_self'}
                  <StyledExternalLink
                    href={element.url}
                    target={
                      element.url?.includes("https") ? "_blank" : element.url
                    }
                    onClickCapture={
                      element.email
                        ? () => copyTextToClipboard(element.email)
                        : () => {}
                    }
                  >
                    {element.label}
                    {element.email ? <Copy size="1rem" /> : null}
                  </StyledExternalLink>
                ))}
              </Column>
            );
          })}
        </RightSection>
      </ResponsiveContainer>
      <HorizontalDivider />
      <Column
        style={{
          gap: ".5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TEXT.SubBody textAlign="center" paddingTop={3}>
          © 2023 Layer-E. All Rights reserved.
        </TEXT.SubBody>
        <ZettablockLogo onClick={() => openLink("https://zettablock.com/")}>
          <img src={assets.logos.zettaLogo} alt="" />
        </ZettablockLogo>
      </Column>
    </FooterWrapper>
  );
}

const ZettablockLogo = styled.div`
  width: 125px;
  opacity: 0.6;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;
export default Footer;

const linkList = [
  {
    title: "Company",
    elements: [
      {
        label: "Layer-E",
        url: "https://layer-e.com/",
      },
      {
        label: "Contact Us",
        url: "",
        email: "hello@layer-e.com",
      },
      {
        label: "Terms of Service",
        url: "https://drive.google.com/file/d/1gl2f-jPpqnw3wipYBOn0HG0qEBVc03uT/view?usp=sharing",
      },
      {
        label: "Privacy Policy",
        url: "https://drive.google.com/file/d/1m_6tx7HTnv2K8jO5NPujU-xUzff-8y5u/view?usp=sharing",
      },
    ],
  },
  {
    title: "Contribute",
    elements: [
      {
        label: "Request a Feature",
        url: "https://layer-e.canny.io/feature-requests",
      },
      {
        label: "Give Feedback",
        url: "https://layer-e.canny.io/user-feedback",
      },
    ],
  },
  {
    title: "Know More",
    elements: [
      {
        label: "Get a Custom Dashboard for your Project",
        url: "https://tally.so/r/3EKRrq",
      },
    ],
  },
];
